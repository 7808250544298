<template>
  <div class="main-plan">
    <div class="plan-content">
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              outlined
              dense
              label="Name"
              v-model="data.name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-select
              outlined
              dense
              label="Duration Type"
              v-model="data.duration_type"
              :items="durationType"
              item-text="name"
              item-value="name"
              :hint="`${server_errors.duration_type}`"
              persistent-hint
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
              class="input-number"
              type="number"
              outlined
              dense
              label="Price"
              v-model="data.price"
              :hint="`${server_errors.price}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-select
              outlined
              dense
              label="Currency"
              :items="currency"
              item-text="name"
              item-value="id"
              v-model="data.currency_id"
              :hint="`${server_errors.currency_id}`"
              persistent-hint
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
              class="input-number"
              type="number"
              outlined
              dense
              label="Max Employee"
              v-model="data.max_employee"
              :hint="`${server_errors.max_employee}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
              class="input-number"
              type="number"
              outlined
              dense
              label="Max User"
              v-model="data.max_user"
              :hint="`${server_errors.max_user}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>


        <v-col cols="6" md="6">
          <v-btn class="btn-save-change" :loading="btnLoading" @click="saveChange">
            Save Change
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      currency: [],
      data: {
        name: "",
        duration_type: "",
        price: "",
        currency_id: "",
        max_user: "",
        max_employee: ""
      },

      server_errors: {
        name: "",
        duration_type: "",
        price: "",
        currency_id: "",
        max_user: "",
        max_employee: ""
      },

      durationType: [
        {
          name: "monthly"
        },
        {
          name: "yearly"
        }
      ]
    }
  },
  methods: {
    getCurrency() {
      this.$axios.get(`admin/currency`).then((res) => {
        this.currency = res.data.data.data;
      }).catch(() => {

      })
    },
    saveChange() {
      this.$axios.post(`admin/plan`, this.data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$router.push({name: "plan.index"})
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  created() {
    this.getCurrency();
  }
}
</script>

<style scoped lang="scss">
.main-plan {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .plan-content {
    width: 400px;
    height: 500px;
    // background-color: #719AFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
